

h1, h2, h3, h4, h5 {
  color: #b62711;
}

a.thumb {
  width: 100%;
  display: block;
  margin: 0px auto;
  position: relative;
  border: 2px solid #eee;
  /* background: transparent url(/images/play_button.png) center center no-repeat; */
  &:hover {
    -webkit-box-shadow: 0px 0px 4px #000;
    -moz-box-shadow: 0px 0px 4px #000;
    box-shadow: 0px 0px 4px #000;
  }
  &>img {
    width: 100%;
  }
  &>span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent url(/images/play_button.png) center center no-repeat;
    filter: alpha(opacity = 60);
    opacity: .6;
  }
}
